import "../App.css";
import { Button, Input, FormGroup, Label } from "reactstrap";
import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

//Initializing login state variables
const initialLoginState = {
  password: "",
  passwordError: "",
  email: "",
  emailError: "",
};

const LOGIN_USER = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      id
      email
      firstName
      lastName
      role
      isVerified
      token
      batches {
        id
        name
        level
        description
        startTime
      }
    }
  }
`;

function Signin(props) {
  const [loginState, setLoginState] = useState(initialLoginState);
  const { addToast } = useToasts();

  const [loginuser, { data, loading, error }] = useMutation(LOGIN_USER, {
    variables: {
      input: {
        email: loginState.email,
        password: loginState.password,
      },
    },
    onError: (err) => {
      if (JSON.stringify(err).includes("User not found!")) {
        addToast("User not found with this email", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      } else if (JSON.stringify(err).includes("Invalid")) {
        addToast("Invalid credintials", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      }
    },
    onCompleted: (data) => {
      sessionStorage.setItem("email", data.login.email);
      localStorage.setItem("token", data.login.token);
      if (data.login.isVerified === false) {
        localStorage.setItem("loggedin", true);
        addToast("User Loggedin successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setTimeout(() => {
          addToast("User verification Required", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        }, 2000);

        setTimeout(() => {
          window.location.href = "/verifyuser";
        }, 4000);
      } else if (data.login.isVerified === true) {
        localStorage.setItem("loggedin", true);
        addToast("User Loggedin successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setTimeout(() => {
          window.location.href = "/homepage";
        }, 2000);
      }
    },
  });

  //Function to move to signup page
  const signuppage = () => {
    props.history.push("/signup");
  };

  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#46B487";
  });
  //Function to validate login state variables
  const validateLogin = () => {
    let passwordError, emailError;

    if (!loginState.email) {
      emailError = "Email is required";
    }
    let emailRegex =
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";

    if (loginState.email && !loginState.email.match(emailRegex)) {
      emailError = "Invalid Email";
    }

    if (!loginState.password) {
      passwordError = "Password is required";
    }
    if (passwordError || emailError) {
      setLoginState({ ...loginState, passwordError, emailError });
      return false;
    }
    return true;
  };

  const signin = () => {
    const isValid = validateLogin();

    if (isValid) {
      loginuser();
    }
  };
  const forgotpassword = () => {
    props.history.push("/forgotpassword");
  };

  return (
    <div>
      <div className="signinmyclass">MioClass</div>
      <div className="cardsignin">
        <div className="homepagesignin">
          <div className="signindiv">
            <span className="headersignin">Sign In</span>
          </div>
          <div className="group">
            <Label className="labelssignin">Email</Label>
            <FormGroup>
              <Input
                className="emailinput"
                type="text"
                name="email"
                value={loginState.email}
                onChange={(event) =>
                  event.target.value
                    ? setLoginState({
                        ...loginState,
                        email: event.target.value,
                        emailError: "",
                      })
                    : setLoginState({
                        ...loginState,
                        email: event.target.value,
                        emailError: "Email is required",
                      })
                }
                placeholder="Email"
              />
            </FormGroup>

            {loginState.emailError ? (
              <div className="validation-error">{loginState.emailError}</div>
            ) : (
              ""
            )}
          </div>
          <div>
            <Label className="labelssignin">Password</Label>
            <FormGroup>
              <Input
                className="passwordinput"
                type="password"
                name="password"
                value={loginState.password}
                onChange={(event) =>
                  event.target.value
                    ? setLoginState({
                        ...loginState,
                        password: event.target.value,
                        passwordError: "",
                      })
                    : setLoginState({
                        ...loginState,
                        password: event.target.value,
                        passwordError: "Password is required",
                      })
                }
                placeholder="Password"
              />
            </FormGroup>

            {loginState.passwordError ? (
              <div className="validation-error">{loginState.passwordError}</div>
            ) : (
              ""
            )}
          </div>

          <Fragment>
            <Button className="signinbutton" onClick={signin}>
              LOG IN{" "}
            </Button>
            <span className="resetpass" onClick={forgotpassword}>
              Forgot Password?
            </span>
          </Fragment>
          <div className="accountsign">
            {/* <span className="account">Don't have an Account?</span> */}
            <span className="signupp" onClick={signuppage}>
              Signup{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Signin);
