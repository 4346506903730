import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import {
  Homepage,
  Assignment,
  Batches,
  Profile,
  Verifyuser,
  Updatepassword,
} from "./components";

function Routes() {
  if (localStorage.getItem("loggedin")) {
    return (
      <Router>
        <Switch>
          <div>
            <ToastProvider>
              <Route exact path="/assignment" render={() => <Assignment />} />
              <Route exact path="/batches" render={() => <Batches />} />
              <Route exact path="/profile" render={() => <Profile />} />
              <Route exact path="/homepage" render={() => <Homepage />} />
              <Route exact path="/verifyuser" render={() => <Verifyuser />} />
              <Route
                exact
                path="/updatepassword"
                render={() => <Updatepassword />}
              />
            </ToastProvider>
          </div>
        </Switch>
      </Router>
    );
  } else {
    return <Redirect to="/" />;
  }
}

export default Routes;
