import "../App.css";
import { Button, Input, FormGroup, Label } from "reactstrap";
import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
//Initializing login state variables
const initialLoginState = {
  password: "",
  passwordError: "",
};
const UPDATEPASSWORD = gql`
  mutation UpdatePassword($password: String!) {
    updatePassword(password: $password) {
      id
      email
      firstName
      lastName
      role
      isVerified
      token
      batches {
        id
        name
        level
        description
        startTime
      }
    }
  }
`;

function Updatepassword(props) {
  const { addToast } = useToasts();
  const [loginState, setLoginState] = useState(initialLoginState);
  const [updatenewpassword] = useMutation(UPDATEPASSWORD, {
    variables: {
      password: loginState.password,
    },
    onCompleted: (data) => {
      localStorage.setItem("token", data.updatePassword.token);
      if (data.updatePassword.isVerified === true) {
        addToast("New password changed successfully.please login", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });

        setTimeout(() => {
          window.location.href = "/";
          localStorage.clear();
        }, 2000);
      }
    },
    onError: (err) => {
      addToast(err.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    },
  });

  //Function to validate login state variables
  const validateLogin = () => {
    let passwordError;

    if (!loginState.password) {
      passwordError = "New Password is required";
    }
    if (passwordError) {
      setLoginState({ ...loginState, passwordError });
      return false;
    }
    return true;
  };
  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#46B487";
  });
  const updatepassword = () => {
    const isValid = validateLogin();
    if (isValid) {
      updatenewpassword();
    }
  };
  return (
    <div>
      <div className="myclass">MioClass</div>

      <div className="cardsignin">
        <div className="homepagesignin">
          <div className="signindiv">
            <span className="headersignin">Update Password</span>
          </div>
          <div>
            <Label className="labelssignin">New Password</Label>
            <FormGroup>
              <Input
                className="passwordinput"
                type="password"
                name="password"
                value={loginState.password}
                onChange={(event) =>
                  event.target.value
                    ? setLoginState({
                        ...loginState,
                        password: event.target.value,
                        passwordError: "",
                      })
                    : setLoginState({
                        ...loginState,
                        password: event.target.value,
                        passwordError: "New Password is required",
                      })
                }
                placeholder="New Password"
              />
            </FormGroup>

            {loginState.passwordError ? (
              <div className="validation-error">{loginState.passwordError}</div>
            ) : (
              ""
            )}
          </div>

          <Fragment>
            <Button className="signinbutton" onClick={updatepassword}>
              Update Password{" "}
            </Button>
          </Fragment>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Updatepassword);
