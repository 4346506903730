import React from "react";
import { Splash, Signin, Signup, Forgotpassword } from "./components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./Routes";
import { ToastProvider, useToasts } from "react-toast-notifications";

function App(props) {
  return (
    <div className="App">
      <Router>
        <Switch>
          <div>
            <ToastProvider>
              <Route
                exact
                path="/"
                render={() =>
                  localStorage.getItem("loggedin") ? "" : <Splash />
                }
              />

              <Route exact path="/signin" render={() => <Signin />} />
              <Route exact path="/signup" render={() => <Signup />} />
              <Route
                exact
                path="/forgotpassword"
                render={() => <Forgotpassword />}
              />

              <Route path="/" render={(props) => <Routes {...props} />} />
            </ToastProvider>
          </div>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
