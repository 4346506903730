import "../App.css";
import { Button } from "reactstrap";
import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../assets/css/bootstrap.min.css";
import "../assets/css/LineIcons.2.0.css";
import "../assets/css/animate.css";
import "../assets/css/tiny-slider.css";
import "../assets/css/glightbox.min.css";
import "../assets/css/main.css";
import { Helmet } from "react-helmet";
import { ReactComponent as LogoSvg } from "../assets/images/logo/white-logo.svg";

// import Page from "index.html";
// var htmlDoc = { __html: Page };

function Splash(props) {
  //Function to move to signin page
  const welcome = () => {
    props.history.push("/signin");
    localStorage.clear();
    sessionStorage.clear();
  };

  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#46B487";
  });

  return (
    <div>
      <meta charSet="utf-8" />
      <title>MioClass</title>
      <meta name="description" content />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={require("../assets/images/favicon.svg")}
      />
      {/* Preloader */}
      {/* <div className="preloader">
        <div className="preloader-inner">
          <div className="preloader-icon">
            <span />
            <span />
          </div>
        </div>
      </div> */}
      {/* /End Preloader */}
      {/* Start Header Area */}
      <header className="header navbar-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                {/* Start Navbar */}
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="index.html">
                    <LogoSvg />
                    {/* <img src={logoSvg} alt="Logo" /> */}
                  </a>
                  <button
                    className="navbar-toggler mobile-menu-btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a
                          href="#home"
                          className="page-scroll active"
                          aria-label="Toggle navigation"
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#features"
                          className="page-scroll"
                          aria-label="Toggle navigation"
                        >
                          Features
                        </a>
                      </li>
                      {/* <li class="nav-item">
                                      <a href="javascript:void(0)" aria-label="Toggle navigation">Overview</a>
                                  </li> */}
                      <li className="nav-item">
                        <a
                          href="#pricing"
                          className="page-scroll"
                          aria-label="Toggle navigation"
                        >
                          Pricing
                        </a>
                      </li>
                      {/* <li class="nav-item">
                                      <a href="javascript:void(0)" aria-label="Toggle navigation">Team</a>
                                  </li> */}
                      {/* <li class="nav-item">
                                      <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                                          data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                                          aria-expanded="false" aria-label="Toggle navigation">Blog</a>
                                      <ul class="sub-menu collapse" id="submenu-1-4">
                                          <li class="nav-item"><a href="javascript:void(0)">Blog Grid Sidebar</a>
                                          </li>
                                          <li class="nav-item"><a href="javascript:void(0)">Blog Single</a></li>
                                          <li class="nav-item"><a href="javascript:void(0)">Blog Single
                                                  Sibebar</a></li>
                                      </ul>
                                  </li> */}
                      <li className="nav-item">
                        <a
                          href="javascript:void(0)"
                          aria-label="Toggle navigation"
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* navbar collapse */}
                  <div className="button add-list-button">
                    <Button onClick={welcome}>Login</Button>
                  </div>
                </nav>
                {/* End Navbar */}
              </div>
            </div>
          </div>
          {/* row */}
        </div>
        {/* container */}
      </header>
      {/* End Header Area */}
      {/* Start Hero Area */}
      <section id="home" className="hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1 className="wow fadeInLeft" data-wow-delay=".4s">
                  Teach and Learn the way you want.
                </h1>
                <p className="wow fadeInLeft" data-wow-delay=".6s">
                  Be part of the first hybrid app for teachers and students.
                </p>
                <div className="button wow fadeInLeft" data-wow-delay=".8s">
                  <a
                    href="https://apps.apple.com/de/app/teach-learn-the-way-you-want/id1630250021?l=en"
                    className="btn"
                  >
                    <i className="lni lni-apple" /> App Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=app.myclass.pro"
                    className="btn btn-alt"
                  >
                    <i className="lni lni-play-store" /> Google Play
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
                <img src={require("../assets/images/hero/phone.png")} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Area */}
      {/* Start Features Area */}
      <section id="features" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  Features
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Freedom of creating and managing your own students and
                  assignments.
                </h2>
                {/* <p class="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                          Ipsum available, but the majority have suffered alteration in some form.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-upload" />
                <h3>Easy Upload &amp; Assign</h3>
                <p>
                  Upload and assign worksheets in the easiest and smartest way
                  ever. Create your own customised worksheets and assign them to
                  your students with just one click. ⏫
                </p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-spellcheck" />
                <h3>Auto Correct</h3>
                <p>
                  Auto correction saves time for teachers to utilise for more
                  important tasks.It provides immediate feedback to students,
                  allowing them to identify and correct mistakes quickly and
                  effectively. 🌠
                </p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-popup" />
                <h3>Smart Notification</h3>
                <p>
                  Personalised Smart Notifications, help increase the engagement
                  and increases the learning by 83% and it helps to keep the
                  motivation among the students. 🔔
                </p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-layers" />
                <h3>One Time Creation</h3>
                <p>
                  You only have to create your materials/worksheets once and you
                  can reuse them forever. It enables to make on-the-fly changes
                  to meet your students' needs, which can be difficult with
                  pre-packaged materials. ✍
                </p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-pencil-alt" />
                <h3>Create your own content</h3>
                <p>
                  Everyone has they own style of teaching. Creating your own
                  worksheets and content allows you to tailor the material to
                  your students' needs, interests, and learning styles. It
                  increases their engagement and motivation for learning. 💻
                </p>
              </div>
              {/* End Single Feature */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Start Single Feature */}
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-crown" />
                <h3>One Stop Solution</h3>
                <p>
                  Say GoodBye! to all the different, complex and stressful tools
                  you use for communication, spreadsheet management, marketing,
                  lead generation and more. We cover it all intelligently in
                  just one app. 🧳
                </p>
              </div>
              {/* End Single Feature */}
            </div>
          </div>
        </div>
      </section>
      {/* End Features Area */}
      {/* Start Achievement Area */}
      <section className="our-achievement section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="title">
                <h2>
                  MioClass is fun, and studies show that hybrid way of learning
                  is the only REAL way to teach new languages
                </h2>
                <p>
                  MioClass is a bridge, where it enables teachers with the tech
                  platform without changing the way they teach
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <div
                    className="single-achievement wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <h3 className="counter">
                      <span id="secondo1" className="countup" cup-end={100}>
                        100
                      </span>
                      %
                    </h3>
                    <p>satisfaction</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div
                    className="single-achievement wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <h3 className="counter">
                      <span id="secondo2" className="countup" cup-end={120}>
                        100
                      </span>
                      +
                    </h3>
                    <p>Happy Users</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div
                    className="single-achievement wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <h3 className="counter">
                      <span id="secondo3" className="countup" cup-end={125}>
                        200
                      </span>
                      +
                    </h3>
                    <p>Downloads</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Achievement Area */}
      {/* Start Pricing Table Area */}
      <section id="pricing" className="pricing-table section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  pricing
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Pricing Plan
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  It is always a great idea to have a quick talk with us to know
                  more about the pricing. 😉
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              {/* Single Table */}
              <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                {/* Table Head */}
                <div className="table-head">
                  <h4 className="title">Trial</h4>
                  <p>Use it to believe it</p>
                  <div className="price">
                    <h2 className="amount">
                      €0
                      <span className="duration" />
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Start Trial
                    </a>
                  </div>
                </div>
                {/* End Table Head */}
                {/* Start Table Content */}
                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  {/* Table List */}
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle" /> Create &amp;
                      Upload Assignments
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Smart
                      Notifications
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Calendar
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Messaging
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Customer
                      Support
                    </li>
                  </ul>
                  {/* End Table List */}
                </div>
                {/* End Table Content */}
              </div>
              {/* End Single Table*/}
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              {/* Single Table */}
              <div className="single-table wow fadeInUp" data-wow-delay=".4s">
                {/* Table Head */}
                <div className="table-head">
                  <h4 className="title">Freelance</h4>
                  <p>Customised for small students group</p>
                  <div className="price">
                    <h2 className="amount">
                      €8/student<span className="duration">/level</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Freelancer
                    </a>
                  </div>
                </div>
                {/* End Table Head */}
                {/* Start Table Content */}
                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  {/* Table List */}
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle" /> Create &amp;
                      Upload Assignments
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Smart
                      Notifications
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Calendar
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Messaging
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Customer
                      Support
                    </li>
                  </ul>
                  {/* End Table List */}
                </div>
                {/* End Table Content */}
              </div>
              {/* End Single Table*/}
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              {/* Single Table */}
              <div className="single-table wow fadeInUp" data-wow-delay=".6s">
                {/* Table Head */}
                <div className="table-head">
                  <h4 className="title">Startup</h4>
                  <p>Customised for language startups</p>
                  <div className="price">
                    <h2 className="amount">
                      €6/student<span className="duration">/level</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Startup
                    </a>
                  </div>
                </div>
                {/* End Table Head */}
                {/* Start Table Content */}
                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  {/* Table List */}
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle" /> Create &amp;
                      Upload Assignments
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Smart
                      Notifications
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Calendar
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Messaging
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Customer
                      Support
                    </li>
                  </ul>
                  {/* End Table List */}
                </div>
                {/* End Table Content */}
              </div>
              {/* End Single Table*/}
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              {/* Single Table */}
              <div className="single-table wow fadeInUp" data-wow-delay=".8s">
                {/* Table Head */}
                <div className="table-head">
                  <h4 className="title">Enterprise</h4>
                  <p>Customised for large language schools</p>
                  <div className="price">
                    <h2 className="amount">
                      €4/student<span className="duration">/level</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Enterprise
                    </a>
                  </div>
                </div>
                {/* End Table Head */}
                {/* Start Table Content */}
                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  {/* Table List */}
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle" /> Create &amp;
                      Upload Assignments
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Smart
                      Notifications
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Calendar
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Messaging
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle" /> Customer
                      Support
                    </li>
                  </ul>
                  {/* End Table List */}
                </div>
                {/* End Table Content */}
              </div>
              {/* End Single Table*/}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Pricing Table Area */}
      {/* Start Call To Action Area */}
      {/* <section className="section call-action">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="cta-content">
                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                  You are using free Lite <br />
                  Version of Appvilla
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Please, purchase full version of the template to get all
                  pages, features and commercial license.
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".6s">
                  <a href="javascript:void(0)" className="btn">
                    Purchase Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* End Call To Action Area */}
      {/* Start Footer Area */}
      <footer className="footer">
        {/* Start Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                {/* Single Widget */}
                <div className="single-footer f-about">
                  <div className="logo">
                    <LogoSvg />
                    {/* <a href="index.html">
                      <img
                        src={require("../assets/images/logo/white-logo.svg")}
                        alt="#"
                      />
                    </a> */}
                  </div>
                  <p>
                    Making the world a better place through the best EdTech
                    solution.
                  </p>
                  <ul className="social">
                    {/* <li><a href="javascript:void(0)"><i class="lni lni-facebook-filled"></i></a></li>
                              <li><a href="javascript:void(0)"><i class="lni lni-twitter-original"></i></a></li>
                              <li><a href="javascript:void(0)"><i class="lni lni-instagram"></i></a></li> */}
                    <li>
                      <a href="https://www.linkedin.com/company/mioclass/">
                        <i className="lni lni-linkedin-original" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://api.whatsapp.com/send?phone=491633142728"
                      >
                        <i className="lni lni-whatsapp" />
                      </a>
                    </li>
                    {/* <li><a href="javascript:void(0)"><i class="lni lni-youtube"></i></a></li> */}
                    {/* <li><a href="javascript:void(0)"><i class="lni lni-pinterest"></i></a></li> */}
                  </ul>
                  <p className="copyright-text">
                    Designed and Developed with loads of ❤️
                  </p>
                </div>
                {/* End Single Widget */}
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    {/* Single Widget */}
                    {/* <div class="single-footer f-link">
                                  <h3>Solutions</h3>
                                  <ul>
                                      <li><a href="javascript:void(0)">Marketing</a></li>
                                      <li><a href="javascript:void(0)">Analytics</a></li>
                                      <li><a href="javascript:void(0)">Commerce</a></li>
                                      <li><a href="javascript:void(0)">Insights</a></li>
                                      <li><a href="javascript:void(0)">Promotion</a></li>
                                  </ul>
                              </div> */}
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    {/* Single Widget */}
                    {/* <div class="single-footer f-link">
                                  <h3>Support</h3>
                                  <ul>
                                      <li><a href="javascript:void(0)">Pricing</a></li>
                                      <li><a href="javascript:void(0)">Documentation</a></li>
                                      <li><a href="javascript:void(0)">Guides</a></li>
                                      <li><a href="javascript:void(0)">API Status</a></li>
                                      <li><a href="javascript:void(0)">Live Support</a></li>
                                  </ul>
                              </div> */}
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-link">
                      <h3>Company</h3>
                      <ul>
                        {/* <li><a href="javascript:void(0)">About Us</a></li> */}
                        {/* <li><a href="javascript:void(0)">Our Blog</a></li>  */}
                        {/* <li><a href="javascript:void(0)">Jobs</a></li> */}
                        {/* <li><a href="javascript:void(0)">Press</a></li> */}
                        <li>
                          <a
                            target="_blank"
                            href="https://api.whatsapp.com/send?phone=491633142728"
                          >
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-link">
                      <h3>Legal</h3>
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://lush-protocol-fcd.notion.site/Terms-Services-0c3607472a1c4dac854c4335f33af6e4"
                          >
                            Terms &amp; Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://lush-protocol-fcd.notion.site/Privacy-Policy-daa5db181ebc445e819bf2b7403f936a"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        {/* <li><a href="javascript:void(0)">Catering Services</a></li> */}
                        {/* <li><a href="javascript:void(0)">Customer Relations</a></li> */}
                        {/* <li><a href="javascript:void(0)">Innovation</a></li> */}
                      </ul>
                    </div>
                    {/* End Single Widget */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/ End Footer Top */}
      </footer>
      {/*/ End Footer Area */}
      {/* ========================= scroll-top ========================= */}
      <a href="#" className="scroll-top">
        <i className="lni lni-chevron-up" />
      </a>
      {/* ========================= JS here ========================= */}
      <Helmet>
        <script src="assets/js/bootstrap.min.js"></script>
        <script src="assets/js/wow.min.js"></script>
        <script src="assets/js/tiny-slider.js"></script>
        <script src="assets/js/glightbox.min.js"></script>
        <script src="assets/js/count-up.min.js"></script>
        <script src="assets/js/main.js"></script>
        {/* <script type="text/javascript">
      var cu = new counterUp({
        start: 0,
        duration: 2000,
        intvalues: true,
        interval: 100,
        append: " ",
      });
      cu.start();
    </script> */}
      </Helmet>
    </div>
  );

  // return <div dangerouslySetInnerHTML={{ __html: myHTML }} />;

  // return <div dangerouslySetInnerHTML={htmlDoc} />;

  // return <iframe src={landingPage}></iframe>;

  return (
    <div>
      <div className="myclass">MyClass</div>
      <div className="card">
        <div className="homepage">
          <span>Teach And Learn The Way You Want</span>
          <Fragment>
            <Button className="homebutton" onClick={welcome}>
              GET STARTED
            </Button>
          </Fragment>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Splash);
