import "../App.css";

import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function Profile(props) {
  const { addToast } = useToasts();

  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#FFFFFF";
  });
  const handlelogout = () => {
    addToast("User Logged Out", {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });

    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();

      window.location.href = "/";
    }, 2000);
  };
  const handlehome = () => {
    props.history.push("/homepage");
  };
  const handlenotification = () => {};
  const handlebatches = () => {
    props.history.push("/batches");
  };
  const handleassignmentpage = () => {
    props.history.push("/assignment");
  };

  return (
    <div>
      <div className="navtab">
        <img
          alt="home"
          onClick={handlehome}
          src="/static/img/home.svg"
          className="homepagehome"
        />
        <img
          alt="notification"
          onClick={handlenotification}
          src="/static/img/bell.svg"
          className="homepagenotif"
        />
      </div>
      <div className="datahomepage">
        <div className="sidebar">
          <img
            src="/static/img/document-file.svg"
            alt="batches"
            className="batches"
            onClick={handlebatches}
          />
          <span style={{ marginTop: "-70px" }}>Batches</span>

          <img
            src="/static/img/assignment.svg"
            alt="assignment"
            className="assignments"
            onClick={handleassignmentpage}
          />
          <span style={{ marginTop: "-70px" }}>Assignment</span>
          <img src="/static/img/user1.svg" alt="profile" className="profile" />
          <span style={{ marginTop: "-70px" }}>Profile</span>

          <img
            alt="logout"
            src="/static/img/power.svg"
            onClick={handlelogout}
            className="logout"
          />
          <span style={{ marginTop: "-70px" }}>Logout</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        ></div>
      </div>
    </div>
  );
}
export default withRouter(Profile);
