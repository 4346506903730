import "../App.css";
import { Button, FormGroup, Input } from "reactstrap";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

const initialstate = {
  otp: "",
  otpError: "",
};
const VERIFY_USER = gql`
  mutation VerifyEmail($otp: String!) {
    verifyEmail(otp: $otp) {
      id
      email
      firstName
      lastName
      role
      isVerified
      token
      batches {
        id
        name
        level
        description
        startTime
      }
    }
  }
`;

const RESEND_VERIFICATIONCODE = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendVerificationEmail(email: $email)
  }
`;

function Verifyuser(props) {
  const { addToast } = useToasts();
  const [state, setState] = useState(initialstate);
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [resendemailverificationcode] = useMutation(RESEND_VERIFICATIONCODE, {
    variables: {
      email: email,
    },
    onError: (err) => {
      addToast("Unable to send verificationcode please login again", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    },
    onCompleted: (data) => {
      if (data.resendVerificationEmail) {
        localStorage.setItem("loggedin", true);

        addToast("New verification code send to your registered gmail", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      }
    },
  });
  const [verifyuser] = useMutation(VERIFY_USER, {
    variables: {
      otp: state.otp,
    },

    onError: (err) => {
      if (
        JSON.stringify(err).includes("Incorrect OTP entered. Please try again")
      ) {
        addToast("Incorrect OTP entered. Please try again.", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      }
      if (
        JSON.stringify(err).includes(
          "The otp was either expired or does not exist. Please request a new one"
        )
      ) {
        addToast(
          "The otp was either expired or does not exist. Please request a new one",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          }
        );
      }
    },
    onCompleted: (data) => {
      if (data.verifyEmail.isVerified === true) {
        localStorage.setItem("loggedin", true);

        addToast("User Verification successfull", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setTimeout(() => {
          window.location.href = "/homepage";
        }, 2000);
      }
    },
  });

  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#46B487";
  });

  //Function to validate login state variables
  const VERIFYUSER = () => {
    let otpError;

    if (!state.otp) {
      otpError = "verificationcode is required";
    }
    if (otpError) {
      setState({ ...state, otpError });
      return false;
    }
    return true;
  };

  const handlesubmit = () => {
    const isValid = VERIFYUSER();

    if (isValid) {
      verifyuser();
    }
  };

  const handleresendotp = () => {
    resendemailverificationcode();
  };
  return (
    <div>
      <div>
        <div className="signinmyclass">MioClass</div>
        <div className="cardsignin">
          <div className="homepagesignin">
            <div className="verifydiv">
              <span>Verification</span>
              <span
                style={{
                  fontSize: "xx-small",
                  marginBottom: "40px",
                  color: "grey",
                  marginTop: "5px",
                }}
              >
                Enter your VerificationCode send to your gmail
              </span>
            </div>

            <FormGroup>
              <Input
                className="emailinput"
                type="text"
                maxLength="6"
                name="otp"
                value={state.otp}
                onChange={(event) =>
                  event.target.value
                    ? setState({
                        ...state,
                        otp: event.target.value,
                        otpError: "",
                      })
                    : setState({
                        ...state,
                        otp: event.target.value,
                        otpError: "verificationcode is required",
                      })
                }
                placeholder="Enter VerificationCode"
              />
              {state.otpError ? (
                <div className="validation-error">{state.otpError}</div>
              ) : (
                ""
              )}
            </FormGroup>

            <p>
              <a
                style={{ color: "blue", fontSize: "12px", cursor: "pointer" }}
                onClick={handleresendotp}
              >
                Resend verification code
              </a>
            </p>
            <Button
              onClick={handlesubmit}
              style={{ marginTop: "0px" }}
              className="verifybutton"
            >
              Verify
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Verifyuser);
