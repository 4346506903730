import "../App.css";

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { useToasts } from "react-toast-notifications";

function Homepage(props) {
  const { addToast } = useToasts();

  const [assignmentsq, setAssignmentsq] = useState([
    {
      level: "A1",
      AssignmentName: "Nouns",
      Description: "noun",
      tag: "#NOUN",
      createddate: "21/12/2022",
      lastedit: "22/12/2022",
      assignto: "fasttrack",
    },
    {
      level: "A2",
      AssignmentName: "ProNouns",
      Description: "pronoun",
      tag: "#PRONOUN",
      createddate: "21/12/2022",
      lastedit: "23/12/2022",
      assignto: "secondclass",
    },
  ]);

  const handlecreateassignment = () => {
    props.history.push("/assignment");
  };
  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#FFFFFF";
  });
  const handlelogout = () => {
    addToast("User Logged Out", {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });

    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }, 2000);
  };

  const handlehome = () => {
    props.history.push("/homepage");
  };
  const handlenotification = () => {};
  const handlebatches = () => {
    props.history.push("/batches");
  };
  const handleassignmentpage = () => {
    props.history.push("/assignment");
  };
  const handleprofile = () => {
    props.history.push("/profile");
  };

  return (
    <div>
      <div className="navtab">
        <img
          alt="home"
          onClick={handlehome}
          src="/static/img/home.svg"
          className="homepagehome"
        />
        <img
          alt="notification"
          onClick={handlenotification}
          src="/static/img/bell.svg"
          className="homepagenotif"
        />
      </div>
      <div className="datahomepage">
        <div className="sidebar">
          <img
            src="/static/img/document-file.svg"
            alt="batches"
            className="batches"
            onClick={handlebatches}
          />
          <span style={{ marginTop: "-70px" }}>Batches</span>

          <img
            src="/static/img/assignment.svg"
            alt="assignment"
            className="assignments"
            onClick={handleassignmentpage}
          />
          <span style={{ marginTop: "-70px" }}>Assignment</span>
          <img
            src="/static/img/user.svg"
            alt="profile"
            className="profile"
            onClick={handleprofile}
          />
          <span style={{ marginTop: "-70px" }}>Proifle</span>

          <img
            alt="logout"
            src="/static/img/power.svg"
            onClick={handlelogout}
            className="logout"
          />
          <span style={{ marginTop: "-70px" }}>Logout</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="tabledetails">
            <Table striped size="sm">
              <thead>
                <tr style={{ backgroundColor: "whitesmoke", height: "7vh" }}>
                  <th style={{ width: "5%", border: "1px solid grey" }}>
                    Level
                  </th>
                  <th style={{ width: "25%", border: "1px solid grey" }}>
                    Assignment name
                  </th>
                  <th style={{ width: "25%", border: "1px solid grey" }}>
                    Description
                  </th>
                  <th style={{ width: "5%", border: "1px solid grey" }}>
                    #Tag
                  </th>
                  <th style={{ width: "15%", border: "1px solid grey" }}>
                    Created Date
                  </th>
                  <th style={{ width: "10%", border: "1px solid grey" }}>
                    Last Edit
                  </th>{" "}
                  <th style={{ width: "25%", border: "1px solid grey" }}>
                    Assigned To
                  </th>
                </tr>
              </thead>
              {assignmentsq.map((dataassignment) => {
                return (
                  <tbody className="tablebody">
                    <tr>
                      <td style={{ height: "5vh" }}>{dataassignment.level} </td>
                      <td>{dataassignment.AssignmentName}</td>
                      <td>{dataassignment.Description} </td>
                      <td>{dataassignment.tag}</td>
                      <td>{dataassignment.createddate} </td>
                      <td>{dataassignment.lastedit}</td>
                      <td
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {dataassignment.assignto}
                        {
                          <img
                            onClick={handlehome}
                            alt="invtie"
                            src="/static/img/invite.svg"
                            className="inviteuser"
                          />
                        }
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </div>
          <div className="createassignment">
            <div>
              <Button className="create" onClick={handlecreateassignment}>
                CREATE ASSIGNMENT
              </Button>
            </div>
            <div>
              {" "}
              <Button className="upload">UPLOAD ASSIGNMENT</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Homepage);
