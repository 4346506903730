import "../App.css";
import { FormGroup, Input, Button, Label, Table } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

const questionstate = {
  responses: [
    {
      qno: "",
      questions: "",
      answer1: "",
      score1: "",
      answer2: "",
      score2: "",
      questiontype: "",
    },
  ],
};

const CREATEASSIGNMENT = gql`
  mutation CreateAssignment {
    createAssignment {
      id
      name
      description
      createdAt
      questions {
        id
        question
        type
        answers {
          answer
          score
        }
      }
      batches {
        id
        name
        level
        description
        startTime
      }
    }
  }
`;
function Assignment(props) {
  const [counter, setCounter] = useState(1);
  const incrementCounter = () => setCounter(counter + 1);
  let decrementCounter = () => setCounter(counter - 1);
  if (counter <= 0) {
    decrementCounter = () => setCounter(1);
  }

  const [question, setQuestion] = useState(questionstate);
  const [level, setLevel] = useState("");
  const [assignmentname, setAssignmentname] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const { addToast } = useToasts();

  //Function to show new question input on clicking add more icon
  const handleaddmore = () => {
    setQuestion((prevState) => ({
      responses: [
        ...prevState.responses,
        {
          qno: "",
          questiontype: "",
          questions: "",
          answer1: "",
          score1: "",
          answer2: "",
          score2: "",
        },
      ],
    }));
  };
  const [createassignment] = useMutation(CREATEASSIGNMENT);

  //Function to add new question input
  const createTextUI = () => {
    return (
      question.length !== 0 &&
      question.responses.map((el, i) => (
        <Fragment>
          {i > 0 ? <div className="group"></div> : ""}
          <tr>
            <td>
              <img
                alt="Add"
                src="/static/img/minus.svg"
                className="minus"
                onClickFunc={decrementCounter}
                onClick={(event) => removeClick(i, event)}
              />
            </td>

            <td></td>
            <td style={{ border: "1px solid grey", alignItems: "center" }}>
              <Input
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                  textAlign: "center",
                }}
                name="qno"
                value={(el.qno = i + 1 || "")}
                onChange={(event) => handlequestion(i, event)}
              />
            </td>
            <td style={{ border: "1px solid grey", alignItems: "center" }}>
              <Input
                type="select"
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                  textAlign: "center",
                  backgroundColor: "transparent",
                }}
                name="questiontype"
                value={el.questiontype}
                onChange={(event) => handlequestion(i, event)}
              >
                <option value="">SELECT</option>
                <option value="PARAGRAPH">PARAGRAPH</option>
                <option value="ONE_WORD">ONE WORD</option>
                <option value="MULTIPLE_WORD">MULTIPLE WORD</option>
                <option value="MULTIPLE_CHOICE">MULTIPLE CHOICE</option>
              </Input>
            </td>
            <td style={{ border: "1px solid grey" }}>
              <Input
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                }}
                name="questions"
                value={el.questions || ""}
                onChange={(event) => handlequestion(i, event)}
              />
            </td>
            <td style={{ border: "1px solid grey" }}>
              <Input
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                }}
                name="answer1"
                value={el.answer1 || ""}
                onChange={(event) => handlequestion(i, event)}
              />
            </td>
            <td style={{ border: "1px solid grey" }}>
              <Input
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                }}
                name="score1"
                value={el.score1 || ""}
                onChange={(event) => handlequestion(i, event)}
              />
            </td>
            <td style={{ border: "1px solid grey" }}>
              <Input
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                }}
                name="answer2"
                value={el.answer2 || ""}
                onChange={(event) => handlequestion(i, event)}
              />
            </td>
            <td style={{ border: "1px solid grey" }}>
              <Input
                style={{
                  height: "5vh",
                  width: "95%",
                  border: "none",
                  outline: "none",
                }}
                name="score2"
                value={el.score2 || ""}
                onChange={(event) => handlequestion(i, event)}
              />
            </td>
          </tr>
        </Fragment>
      ))
    );
  };

  //Function to set question values
  const handlequestion = (i, e) => {
    let responses = [...question.responses];
    responses[i] = { ...responses[i], [e.target.name]: e.target.value };
    setQuestion({ responses });
  };

  //Function to remove selected question input
  const removeClick = (i, event) => {
    let responses = [...question.responses];
    responses.splice(i, i === i);
    setQuestion({ responses });
  };
  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#FFFFFF";
  });
  const handlecancel = () => {
    props.history.push("/homepage");
  };
  const handlehome = () => {
    props.history.push("/homepage");
  };
  const handlelogout = () => {
    addToast("User Logged Out", {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });

    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();

      window.location.href = "/";
    }, 2000);
  };
  const handlecreate = () => {
    let input = {};
    input = {
      level: level,
      assignmentname: assignmentname,
      description: description,
      tag: tag,
      responses: question.responses,
    };
  };
  const handleassignmentpage = () => {
    props.history.push("/assignment");
  };
  const handlebatches = () => {
    props.history.push("/batches");
  };
  const handleprofile = () => {
    props.history.push("/profile");
  };
  return (
    <div>
      <div className="navtab">
        <img
          alt="home"
          onClick={handlehome}
          src="/static/img/home.svg"
          className="assignhome"
        />
        <img
          alt="logout"
          onClick={handlelogout}
          src="/static/img/powerassign.svg"
          className="assignpower"
        />
      </div>
      <div className="datahomepage">
        <div className="sidebar">
          <img
            src="/static/img/document-file.svg"
            alt="batches"
            className="batches"
            onClick={handlebatches}
          />
          <span style={{ marginTop: "-70px" }}>Batches</span>

          <img
            src="/static/img/assignment1.svg"
            alt="assignment"
            className="assignments"
            onClick={handleassignmentpage}
          />
          <span style={{ marginTop: "-70px" }}>Assignment</span>
          <img
            src="/static/img/user.svg"
            alt="profile"
            className="profile"
            onClick={handleprofile}
          />
          <span style={{ marginTop: "-70px" }}>Profile</span>

          <img
            alt="logout"
            src="/static/img/power.svg"
            onClick={handlelogout}
            className="logout"
          />
          <span style={{ marginTop: "-70px" }}>Logout</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-around",
              }}
            >
              <FormGroup>
                <Label className="labelassign">Level</Label>
                <Input
                  className="labelinput"
                  placeholder="Enter Level"
                  name="level"
                  value={level}
                  onChange={(event) => setLevel(event.target.value)}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label className="labelassign">Assignment Name</Label>
                <Input
                  className="labelinput"
                  placeholder="Enter Assignment Name"
                  name="assignmentname"
                  value={assignmentname}
                  onChange={(event) => setAssignmentname(event.target.value)}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label className="labelassign">Description</Label>
                <Input
                  className="labelinput"
                  placeholder="Enter Description"
                  name="description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label className="labelassign">Tag</Label>
                <Input
                  className="labelinput"
                  placeholder="Enter Tag"
                  name="tag"
                  value={tag}
                  onChange={(event) => setTag(event.target.value)}
                ></Input>
              </FormGroup>
            </div>

            <div className="tabledetails">
              <Table striped size="sm">
                <thead>
                  <tr style={{ height: "7vh" }}>
                    <th style={{ width: "1%" }}>
                      {" "}
                      <img
                        alt="delete"
                        src="/static/img/add_more.svg"
                        className="addmore"
                        onClickFunc={incrementCounter}
                        onClick={handleaddmore}
                      />
                    </th>{" "}
                    <th style={{ width: "1%" }}></th>{" "}
                    <th style={{ width: "1%", border: "1px solid grey" }}>
                      Q.No.
                    </th>{" "}
                    <th style={{ width: "8%", border: "1px solid grey" }}>
                      Question Type
                    </th>
                    <th style={{ width: "15%", border: "1px solid grey" }}>
                      Question
                    </th>
                    <th style={{ width: "5%", border: "1px solid grey" }}>
                      Answer1
                    </th>
                    <th style={{ width: "5%", border: "1px solid grey" }}>
                      Score1
                    </th>
                    <th style={{ width: "5%", border: "1px solid grey" }}>
                      Answer2
                    </th>
                    <th style={{ width: "5%", border: "1px solid grey" }}>
                      Score2
                    </th>
                  </tr>
                </thead>
                <tbody>{createTextUI()}</tbody>
              </Table>
            </div>
          </div>
          <div className="createassignment">
            <div>
              <Button className="create" onClick={handlecreate}>
                CREATE
              </Button>
            </div>
            <div>
              {" "}
              <Button
                className="upload"
                onClick={handlecancel}
                style={{ backgroundColor: "#3f51b5", border: "none" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Assignment);
