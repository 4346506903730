import "../App.css";
import { Button, Input, FormGroup, Label } from "reactstrap";
import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

//Initializing login state variables
const initialLoginState = {
  verificationcode: "",
  verificationcodeError: "",
  email: "",
  emailError: "",
};
const VERIFYEMAIL = gql`
  mutation ResendVerificationEmailForPasswordReset($email: String!) {
    resendVerificationEmailForPasswordReset(email: $email)
  }
`;
const VERIFYEMAILCODE = gql`
  mutation VerifyEmailForgotPassword($input: ForgotPasswordInput!) {
    verifyEmailForgotPassword(input: $input) {
      id
      email
      firstName
      lastName
      role
      isVerified
      token
      batches {
        id
        name
        level
        description
        startTime
      }
    }
  }
`;
function Forgotpassword(props) {
  const [loginState, setLoginState] = useState(initialLoginState);
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);

  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#46B487";
  });

  const [verifyemail] = useMutation(VERIFYEMAIL, {
    variables: {
      email: loginState.email,
    },
    onCompleted: (data) => {
      addToast("Verificationcode send to your gmail", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      setShow(true);
    },
    onError: (err) => {
      addToast(err.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      setShow(false);
    },
  });
  const [verifyemailcode] = useMutation(VERIFYEMAILCODE, {
    variables: {
      input: {
        email: loginState.email,
        otp: loginState.verificationcode,
      },
    },
    onCompleted: (data) => {
      localStorage.setItem("token", data.verifyEmailForgotPassword.token);

      if (data.verifyEmailForgotPassword.isVerified === true) {
        localStorage.setItem("loggedin", true);

        addToast("gmail verification successfull", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });

        setTimeout(() => {
          window.location.href = "/updatepassword";
        }, 2000);
      }
    },
    onError: (err) => {
      addToast(err.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    },
  });
  const validateemail = () => {
    let emailError;

    if (!loginState.email) {
      emailError = "Email is required";
    }
    let emailRegex =
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";

    if (loginState.email && !loginState.email.match(emailRegex)) {
      emailError = "Invalid Email";
    }

    if (emailError) {
      setLoginState({ ...loginState, emailError });
      return false;
    }
    return true;
  };
  //Function to validate login state variables
  const validateLogin = () => {
    let verificationcodeError, emailError;

    if (!loginState.email) {
      emailError = "Email is required";
    }
    let emailRegex =
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";

    if (loginState.email && !loginState.email.match(emailRegex)) {
      emailError = "Invalid Email";
    }

    if (!loginState.verificationcode) {
      verificationcodeError = "Password reset code is required";
    }
    if (verificationcodeError || emailError) {
      setLoginState({ ...loginState, verificationcodeError, emailError });
      return false;
    }
    return true;
  };

  const verify = () => {
    const isValid = validateemail();

    if (isValid) {
      verifyemail();
    }
  };

  const submit = () => {
    const isValid = validateLogin();

    if (isValid) {
      verifyemailcode();
    }
  };

  return (
    <div>
      <div className="signinmyclass">MioClass</div>
      <div className="cardsignin">
        <div className="homepagesignin">
          <div className="signindiv">
            <span className="headersignin">Forgot Password</span>
          </div>

          <div className="forgotemail">
            <Label className="labelssignin">Email</Label>
            <div>
              <FormGroup>
                <Input
                  className="emailinputforgot"
                  type="text"
                  name="email"
                  value={loginState.email}
                  onChange={(event) =>
                    event.target.value
                      ? setLoginState({
                          ...loginState,
                          email: event.target.value,
                          emailError: "",
                        })
                      : setLoginState({
                          ...loginState,
                          email: event.target.value,
                          emailError: "Email is required",
                        })
                  }
                  placeholder="Email"
                />
              </FormGroup>
            </div>
            {loginState.emailError ? (
              <div className="validation-error">{loginState.emailError}</div>
            ) : (
              ""
            )}

            <Button onClick={verify} className="verifygmailbutton">
              Verify gmail
            </Button>
          </div>
          {show === true ? (
            <div>
              <div style={{ marginTop: "10px" }}>
                <Label className="labelssignin">Password reset code</Label>
                <FormGroup>
                  <Input
                    className="passwordinput"
                    maxLength="6"
                    type="verificationcode"
                    name="verificationcode"
                    value={loginState.verificationcode}
                    onChange={(event) =>
                      event.target.value
                        ? setLoginState({
                            ...loginState,
                            verificationcode: event.target.value,
                            verificationcodeError: "",
                          })
                        : setLoginState({
                            ...loginState,
                            verificationcode: event.target.value,
                            verificationcodeError:
                              "Password reset code is required",
                          })
                    }
                    placeholder="Password reset code"
                  />
                </FormGroup>

                {loginState.verificationcodeError ? (
                  <div className="validation-error">
                    {loginState.verificationcodeError}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <Button className="forgotbutton" onClick={submit}>
                {" "}
                Verify gmail and password reset code
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
export default withRouter(Forgotpassword);
