import "../App.css";
import { Button, FormGroup, Input, Label } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

//Initializing state variables
const initialState = {
  email: "",
  emailError: "",
  password: "",
  passwordError: "",
  firstName: "",
  firstNameError: "",
  lastName: "",
  lastNameError: "",
  isVerified: false,
  isTeacher: true,
  inviteCode: null,
};

const REGISTER_USER = gql`
  mutation Register($input: RegisterUserInput!) {
    register(input: $input) {
      id
      email
      firstName
      lastName
      role
      isVerified
      token
    }
  }
`;

function Signup(props) {
  const { addToast } = useToasts();

  const [state, setState] = useState(initialState);
  const [registeruser, { data, loading, error }] = useMutation(REGISTER_USER, {
    variables: {
      input: {
        email: state.email,
        password: state.password,
        firstName: state.firstName,
        lastName: state.lastName,
        isVerified: state.isVerified,
        isTeacher: state.isTeacher,
        inviteCode: state.inviteCode,
      },
    },

    onError: (err) => {
      if (
        JSON.stringify(err).includes(
          "User already registered with this email address. Please try logging in instead"
        )
      ) {
        addToast(
          "User already registered with this email address. Please try logging in instead",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          }
        );
      }
    },

    onCompleted: (data) => {
      sessionStorage.setItem("email", data.register.email);

      localStorage.setItem("token", data.register.token);
      if (data.register.isVerified === false) {
        localStorage.setItem("loggedin", true);

        addToast("User Registred Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setTimeout(() => {
          addToast("User verification Required", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        }, 2000);

        setTimeout(() => {
          window.location.href = "/verifyuser";
        }, 4000);
      } else if (data.register.isVerified === true) {
        localStorage.setItem("loggedin", true);
        addToast("User Registred and  verified successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setTimeout(() => {
          window.location.href = "/homepage";
        }, 2000);
      }
    },
  });

  const signinpage = () => {
    props.history.push("/signin");
  };
  //Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = "#46B487";
  });
  //Function to validate signup state variables
  const validate = () => {
    let firstNameError, lastNameError, emailError, passwordError;

    if (!state.firstName) {
      firstNameError = "FullName is required";
    }

    if (!state.lastName) {
      lastNameError = "LastName is required";
    }
    if (!state.email) {
      emailError = "Email is required";
    }
    let emailRegex =
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";

    if (state.email && !state.email.match(emailRegex)) {
      emailError = "Invalid Email";
    }

    if (!state.password) {
      passwordError = "Password is required";
    }

    if (firstNameError || lastNameError || emailError || passwordError) {
      setState({
        ...state,
        firstNameError,
        lastNameError,
        emailError,
        passwordError,
      });
      return false;
    }
    return true;
  };

  //Function to register new user
  const signup = async () => {
    const isValid = validate();
    if (isValid) {
      registeruser();
    }
  };

  return (
    <div>
      <div className="myclasssignup">MioClass</div>

      <div className="cardsignup">
        <div className="signupdiv">
          <span className="headersignup">Sign up</span>
        </div>
        <div className="group">
          <Label className="labelssignup">Email</Label>
          <FormGroup>
            <Input
              className="emailinputup"
              type="text"
              name="email"
              value={state.email}
              onChange={(event) =>
                event.target.value
                  ? setState({
                      ...state,
                      email: event.target.value,
                      emailError: "",
                    })
                  : setState({
                      ...state,
                      email: event.target.value,
                      emailError: "Email is required",
                    })
              }
              placeholder="email"
            />
          </FormGroup>

          {state.emailError ? (
            <div className="validation-error">{state.emailError}</div>
          ) : (
            ""
          )}
        </div>

        <div className="group">
          <Label className="labelssignup">Password</Label>
          <FormGroup>
            <Input
              className="passwordinputup"
              type="password"
              name="password"
              value={state.password}
              onChange={(event) =>
                event.target.value
                  ? setState({
                      ...state,
                      password: event.target.value,
                      passwordError: "",
                    })
                  : setState({
                      ...state,
                      password: event.target.value,
                      passwordError: "Password is required",
                    })
              }
              placeholder="password"
            />
          </FormGroup>

          {state.passwordError ? (
            <div className="validation-error">{state.passwordError}</div>
          ) : (
            ""
          )}
        </div>
        <div className="group">
          <Label className="labelssignup">First Name</Label>
          <FormGroup>
            <Input
              className="fullnameinput"
              type="text"
              name="firstName"
              value={state.firstName}
              onChange={(event) =>
                event.target.value
                  ? setState({
                      ...state,
                      firstName: event.target.value,
                      firstNameError: "",
                    })
                  : setState({
                      ...state,
                      firstName: event.target.value,
                      firstNameError: "firstName is required",
                    })
              }
              placeholder="first name"
            />
          </FormGroup>
          {state.firstNameError ? (
            <div className="validation-error">{state.firstNameError}</div>
          ) : (
            ""
          )}
        </div>
        <div className="group">
          <Label className="labelssignup">Last Name</Label>
          <FormGroup>
            <Input
              className="fullnameinput"
              type="text"
              name="lastName"
              value={state.lastName}
              onChange={(event) =>
                event.target.value
                  ? setState({
                      ...state,
                      lastName: event.target.value,
                      lastNameError: "",
                    })
                  : setState({
                      ...state,
                      lastName: event.target.value,
                      lastNameError: "LastName is required",
                    })
              }
              placeholder="last name"
            />
          </FormGroup>
          {state.lastNameError ? (
            <div className="validation-error">{state.lastNameError}</div>
          ) : (
            ""
          )}
        </div>

        <Fragment>
          <Button className="signupbutton" onClick={signup}>
            SIGN UP{" "}
          </Button>
        </Fragment>
        <div className="accountsign">
          <span className="signup">Already have an Account?</span>{" "}
          <span className="signupp" onClick={signinpage}>
            Signin{" "}
          </span>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Signup);
